.title {
    font-size: clamp(2rem, 5vw, 2rem);
    background-image: linear-gradient(45deg, #ff6ec7, #ff0000, #0099ff, #ffcc00);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    animation: glowing 1.5s ease-in-out infinite;
    font-family: Arial, sans-serif;
  }
  
  .seconddiv {
    background-color: #0c1a5e;
  }
  
  .select-style {
    width: 200px;
    margin-right: 10px;
    background-color: white;
    color: #1702fa;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #1cff03;
  }
  
  .date-picker-style {
    margin-right: 10px;
    background-color: white;
    color: #1702fa;
    border-radius: 5px;
    border: 1px solid #1cff03;
    padding: 5px;
  }
  
  .input-style {
    margin-bottom: 20px;
    padding: 5px;
    width: 200px;
    background-color: white;
    color: #1cff03;
    border-radius: 5px;
    border: 1px solid #1cff03;
  }
  
  .pagination-btn {
    padding: 4px 12px;
    transition: background-color 0.3s ease;
  
    &.disabled {
      background-color: #d1d5db;
      color: #6b7280;
      cursor: not-allowed;
    }
  
    &.active {
      background-color: #3b82f6;
      color: white;
  
      &:hover {
        background-color: #2563eb;
      }
    }
  }
  
  .custom-tooltip {
    background-color: #1a202c;
    color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    line-height: 1.5;
  }
  
  .custom-tooltip p {
    margin: 5px 0;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #38bdf8;
  }
  
  .stock-quantity {
    color: #4ade80;
  }
  
  .sales-quantity {
    color: #60a5fa;
  }
  
  .sales-amount {
    color: #facc15;
  }
  
  .text-center {
    text-align: center;
  }
