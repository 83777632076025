
#chart {
    color: gray;
    width: 100%;
    margin-bottom: 20px;
  
    /* Nesting to target SVG element */
    svg {
      background-color: gray; /* Set background color for the chart */
  
      /* Target the paths inside the SVG */
      path {
        fill: transparent; /* Set fill color for chart paths */
      }
    }
  
    /* Target the tooltip */
    .apexcharts-tooltip {
      border: 1px solid #f15959; /* Add border to tooltip */
      background-color: #fdfdfb; /* Set background color for tooltip */
      color: #086e14; /* Set text color for tooltip */
    }
  
    /* Target the legend */
    .apexcharts-legend {
      display: none;
      padding: 10px; /* Add padding to legend */
      background-color: #f9f9f9; /* Set background color for legend */
      border: 1px solid #ddd; /* Add border to legend */
  
      /* Target legend labels */
      .apexcharts-legend-text {
        color: #f00707; /* Set text color for legend labels */
      }
    }
  }
  
  /* SalesProfitOverview.scss */

.top-left-header {
    fill: #333; /* Text color for top left header */
  }
  
  .top-right-header {
    fill: #333; /* Text color for top right header */
  }
  