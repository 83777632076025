@keyframes blink {
    50% {
      background-color: rgb(142, 255, 101);
    }
  }
  
  .animate-blink {
    animation: blink 1s step-start infinite;
  }
  

  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

  .digital-text {
    font-family: 'Orbitron', sans-serif; /* Sleek, modern digital font */
    letter-spacing: 1px; /* Adjust spacing for a more balanced look */
    font-size: 12px; /* Slightly larger for readability */
    color:#fa1f02; /* Neon green color for digital effect */
    font-weight: 600;
  }



/* Slow down the movement of the rounded toggle */
.ant-switch {
  transition: background-color 3s ease-in-out !important;

  &.online {
    background-color: rgb(1, 155, 1) !important;
  }

  &.offline {
    background-color: red !important;
  }
}

.ant-switch-handle {
  transition: left 1s ease-in-out !important; /* Slow-motion for toggle slider */
}