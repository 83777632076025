/* Hide scrollbar for WebKit browsers */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Internet Explorer, Edge, and Firefox */
body {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
