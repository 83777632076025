#myBarChart {
    /* Set a fixed width to ensure proper truncation */
    max-width: 100%;
  }
  
  .chartjs-render-monitor {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //BELOW GIVEN CSS FOR DASHBORD PAGE SALES AND INVETORY MENAGEMENT BAR CHART CSS
  
  .input-placeholder::placeholder {
    color: #1cff03;
    opacity: 1; /* Ensures color visibility */
  }

  /* Style for DatePicker placeholder */
.input-placeholder .ant-picker-input input::placeholder {
  color: #1cff03; /* Sets the placeholder color */
}
