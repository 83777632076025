/* Background styling */
.login-container {
    background: url('../Assets/login/Farm2Bag.png') center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-attachment: fixed; /* Parallax effect */
    box-shadow: inset 0 0 300px rgba(0, 0, 0, 0.3); /* Adds depth to background */
}

/* Login box styling */
.login-box {
    background: rgba(255, 255, 255, 0.85); /* Slightly transparent white */
    padding: 50px;
    padding-top: 10px;
    width: 100%;
    max-width: 450px;
    border-radius: 20px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    transform: translateY(-50px); /* Slight downward position */
    animation: slideUp 0.5s ease-out forwards;
}

/* Header styling */
.store-title {
    font-size: 60px;
    color: #30f80d;
    font-weight: 700;
    margin-bottom: 1px;
    letter-spacing: 2px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 1px 2px rgba(242, 5, 5, 0.936); /* Subtle text shadow */
}

.subtitle {
    font-size: 18px;
    color: #f67505;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
}

/* Input styling */
.input-group {
    margin-bottom: 25px;
    position: relative;
}

.input-field {
    width: 100%;
    padding: 14px 20px;
    padding-left: 50px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;
}

.input-field:focus {
    border-color: #03f123;
    outline: none;
    box-shadow: 0 0 10px rgba(88, 237, 1, 0.6); /* Focus glow effect */
}

.input-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 15px;
    opacity: 0.7; /* Slight transparency for icons */
}

/* Error text styling */
.error-text {
    color: #ff4d4f;
    font-size: 13px;
    margin-top: 5px;
    font-family: 'Roboto', sans-serif;
}

/* Button styling */
.login-button {
    width: 100%;
    padding: 16px;
    background-color: #4A772F;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.login-button:hover {
    background-color: #05155c;
    transform: translateY(-5px); /* Slight lift effect */
    box-shadow: 0 6px 15px rgba(49, 251, 4, 0.1); /* Shadow effect on hover */
}

.login-button:active {
    transform: translateY(2px); /* Button presses down on click */
}

/* Success message styling */
.success-message {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8); 
    color: #4A772F;
    font-weight: 700;
    font-size: 22px;
    animation: fadeIn 1s ease-out forwards;
}

.success-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    animation: rotateIcon 1s ease-in-out forwards;
}

/* Animation for login box */
@keyframes slideUp {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Fade-in for success message */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Rotate success icon */
@keyframes rotateIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Success message styling */
.success-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(25, 22, 22, 0.7); /* Dark overlay background */
    z-index: 9999; /* Ensures it appears above all other content */
    animation: fadeIn 0.5s ease-out forwards; /* Smooth fade-in */
  }
  
  /* Success content styling (content inside the success message box) */
  .success-content {
    background-color: #fff;
    padding: 40px 60px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: popIn 0.5s ease-out forwards;
    max-width: 500px;
    width: 90%;
  }
  
  /* Success icon and text */
  .success-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    animation: tickAnimation 0.6s ease-out forwards;
  }
  
  .success-text {
    font-size: 24px;
    color: #fa7b04;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    animation: slideIn 0.5s ease-out forwards;
  }
  
  /* Animation for fade-in */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Pop-in effect for the success content */
  @keyframes popIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Animation for the tick mark */
  @keyframes tickAnimation {
    0% {
      stroke-dasharray: 0;
      stroke-dashoffset: 40;
    }
    100% {
      stroke-dasharray: 40;
      stroke-dashoffset: 0;
    }
  }
  
  /* Slide-in effect for the success text */
  @keyframes slideIn {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* New class for the image */
  .success-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    animation: moveImageLeftToRight 1.5s ease-out forwards;
  }
  
  /* Animation to move the image from left to right */
  @keyframes moveImageLeftToRight {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(0);
    }
  }
  