// Overlay with fade-in animation
.network-slow-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .network-slow-popup {
    position: relative;
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    text-align: center;
    max-width: 450px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.4s ease-in-out forwards;
    transform: translateY(-20px);
    opacity: 0;
  }
  
  @keyframes slideDown {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  // Popup header and message
  .network-slow-popup h3 {
    margin: 0 0 10px;
    font-size: 1.4rem;
    color: #333;
    font-weight: 600;
  }
  
  .network-slow-popup p {
    margin: 0 0 30px;
    font-size: 1.1rem;
    color: #444;
    line-height: 1.5;
    font-weight: 400;
    max-width: 350px;
    margin: 0 auto 30px;  /* Center-align and add bottom margin */
  }
  
  // Container for the buttons
  .network-slow-popup div {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Adds space between the buttons */
  }
  
  // Close button
  .network-slow-popup button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.8s;
  
    &:hover {
      background: #df3501;
    }
  }
  
  // Sales Button
  .network-slow-popup .sales-button {
    background: #28a745;  /* Green color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  
    &:hover {
      background: #218838; /* Darker green on hover */
    }
  }
  
  // Satellite-Tower Animation Elements with Fire Effect
  .satellite-tower-animation {
    position: relative;
    width: 100%;
    height: 80px;
    margin: 0 auto 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  
  // Satellite Image (Top-left corner)
  .satellite {
    width: 50px; /* Increased size */
    height: 50px;
    background-size: cover;
    background-image: url('../Assets/Billingpage/satellite.png'); // Replace with the actual path to your satellite image
    position: absolute;
    left: 10px;
    top: 0px;
  }
  
  // Tower Image (Top-right corner)
  .tower {
    width: 50px; /* Increased size */
    height: 50px;
    background-size: cover;
    background-image: url('../Assets/Billingpage/tower.png'); // Replace with the actual path to your network tower image
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  // Signal animation with slow pulse
  .signal {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    top: 36px;
    left: 50px;
    animation: slowSignal 2.5s ease-in-out infinite;
  }
  
  @keyframes slowSignal {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: translateX(150px);
      opacity: 0.2;
    }
  }
  